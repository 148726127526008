import React from "react";
import styles from "./index.module.less";
import LabContent from "../../../../component/lab-content";

const content = [
  {
    id: 0,
    img: "https://ts1.cn.mm.bing.net/th/id/R-C.7a5cbf29b374c386a5f8da6bbcd1a7b4?rik=WuyutOtFlubpKw&riu=http%3a%2f%2fpic.ntimg.cn%2ffile%2f20191115%2f29591657_234313813081_2.jpg&ehk=LUR4Glyf0VPdvLqF3Uv9zldQLQ1P0kf9dnv3pUkthVw%3d&risl=&pid=ImgRaw&r=0",
    title: "两分钟了解虚拟仿真",
    des: "虚拟仿真技术，也称为虚拟现实技术或模拟技术，是一种使用计算机技术来模拟真实系统或场景的技术。它通过建立一个虚拟的环境，使用户能够与这个虚拟的环境进行交互，就像真实世界一样。这种技术可以应用于各种领域，如航空航天、军事、医疗、教育、游戏等。",
    url: "https://www.bilibili.com/video/BV1sa4y1q7Kv/?spm_id_from=333.337.search-card.all.click",
  },

  {
    id: 1,
    img: "https://ts1.cn.mm.bing.net/th/id/R-C.f8c7acbed08c09e2a36dde4b773954df?rik=AguOG6LdndoVZg&riu=http%3a%2f%2fwww.runsunedu.com%2fuploads%2fallimg%2f210626%2f1-210626143444546.jpg&ehk=gfLtpMyFK%2b5CyHMc3ZegsvXfp3gJGRuL1JlNMHspKiw%3d&risl=&pid=ImgRaw&r=0",
    title: "国家虚拟仿真实验教学课程共享平台（实验空间）",
    des: "国家高等教育智慧教育平台。经过对已上线实验空间并提供共享服务的3000多门虚拟仿真实验课程的层层遴选，最终共有300门课程被选入首批上线国家高等教育智慧教育平台的虚仿实验板块。",
    url: "https://www.bilibili.com/video/BV15x4y1C7Z9/?spm_id_from=333.337.search-card.all.click&vd_source=bcdccb2415cc798b78fb2725ec56f4ec",
  },
  {
    id: 2,
    img: "https://tse1-mm.cn.bing.net/th/id/OIP-C.zvuczT7xiEL2UYlWBCodGwHaFj?rs=1&pid=ImgDetMain",
    title: "乙酰水杨酸-化学虚拟仿真实验室介绍",
    des: "乙酰水杨酸-化学虚拟仿真实验室项目作品介绍视频",
    url: "https://www.bilibili.com/video/BV1kh4y1n75C/?spm_id_from=333.337.search-card.all.click&vd_source=bcdccb2415cc798b78fb2725ec56f4ec",
  },

];

export default function ClassCom() {
  return <LabContent content={content} />
}

import React, { useEffect } from "react";
import type { TabsProps } from "antd";
import { Tabs, theme } from "antd";
import StickyBox from "react-sticky-box";
import styles from "./index.module.less";
// import LabContent from "../lab-content";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const linkTab = [
  {
    id: "/index/lab",
    label: "热门实验",
    content: <Outlet />,
  },
  {
    id: "/index/modal",
    label: "模型展示",
    content: <Outlet />,
     disabled: true,
  },
  {
    id: "/index/recommend",
    label: "趣味推荐",
    content: <Outlet />,
  },
  {
    id: "/index/class",
    label: "课程学习",
    content: <Outlet />,
  },

];

const items = linkTab.map((item) => {
  return {
    ...item,
    label: item.label,
    key: item.id,
  };
});

const Tab: React.FC = () => {
  const nav = useNavigate()
  const location = useLocation()
  const path = location.pathname
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const renderTabBar: TabsProps["renderTabBar"] = (props, DefaultTabBar) => (
    <StickyBox offsetTop={64} offsetBottom={20} style={{ zIndex: 1 }}>
      <DefaultTabBar {...props} style={{ background: colorBgContainer }} />
    </StickyBox>
  );

  const tabChange = (e:any) => {
    nav(e)
  };


  return (
    <div className={styles.container}>
      <Tabs
        defaultActiveKey="/index/lab"
        activeKey={path}
        renderTabBar={renderTabBar}
        items={items}
        onChange={tabChange}
      />
    </div>
  );
};

export default Tab;

import React from 'react'
import styles from './index.module.less'
import img from './logo.png'

export default function Logo() {
    
  return (
    <div className={styles.container}>
        <div onClick={()=>window.open('https://www.hncu.edu.cn/')}><img className={styles.img} src={img} alt="logo" /></div>
    </div>
  )
}

import React from 'react'
import styles from './index.module.less'
import LabContent from '../../../../component/lab-content';


const content = [
  // {
  //   id: 0,
  //   img: "https://ts1.cn.mm.bing.net/th/id/R-C.f53e729846f226805d024614f131a35a?rik=UNhpy6HlpeTm1w&riu=http%3a%2f%2fnews.cjn.cn%2fcsqpd%2fwh_20004%2f202312%2fW020231228698551919727.png&ehk=g4pM%2fJts6jDISOt6wqWgtBXFQlrX3F8DF4BiS2hPPGU%3d&risl=&pid=ImgRaw&r=0",
  //   title: "xiaomi su7",
  //   des: "超炫酷的3D页面",
  //   url: "https://gamemcu.com/su7/",
  // },
  {
    id: 0,
    img: "https://tse1-mm.cn.bing.net/th/id/OIP-C.ppomB8SIrndkziyxrtcjhAHaE7?rs=1&pid=ImgDetMain",
    title: "茶与墨",
    des: "【化学实验】茶与墨",
    url: "https://www.bilibili.com/video/BV1cW4y1a79y/?spm_id_from=333.337.search-card.all.click&vd_source=bcdccb2415cc798b78fb2725ec56f4ec",
  },{
    id: 1,
    img: "https://pic.52112.com/180425/180425_178/Azsd2a4KMS_small.jpg",
    title: "金与蓝魔法",
    des: "【化学实验】金与蓝魔法",
    url:'https://www.bilibili.com/video/BV1Fd4y1T7sX/?spm_id_from=333.788&vd_source=bcdccb2415cc798b78fb2725ec56f4ec'
  }

];

export default function Recommend() {
  return (
    <LabContent content={content} />
  )
}

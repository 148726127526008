// import img0 from "./6.jpg";
import img2 from "./2.jpg";
import img1 from "./1.jpg";
import img3 from "./img/0.png";
// import img4 from "./4.jpg";
// import img5 from "./5.jpg";
import img6 from "./img/3.png";
// import img7 from "./7.png";
import styles from "./index.module.less";

import React from "react";
import { Carousel, Col, Row } from "antd";

const imgList = [
  {
    key: 1,
    url: img2,
  },
  {
    key: 2,
    url: img6,
  },
  {
    key: 4,
    url: img3,
  },
  {
    key: 5,
    url: img1,
  },
  // {
  //   key: 43,
  //   url: img0,
  // },
  // {
  //   key: 54325,
  //   url: img4,
  // },
  // {
  //   key: 40,
  //   url: img5,
  // },
  // {
  //   key: 740,
  //   url: img7,
  // },
  
];

const App: React.FC = () => {
  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };

  return (
    <Row>
      <Col md={24} xs={0}>
        <Carousel afterChange={onChange} autoplay draggable speed={500} autoplaySpeed={3000}>
          {imgList.map((e) => {
            return (
              <div key={e.key} className={styles.swiperBox}>
                {/* <h1 className={styles.text}>仪器分析虚拟仿真平台</h1> */}
                <img src={e.url} alt="" className={styles.img} />
              </div>
            );
          })}
        </Carousel>
      </Col>
    </Row>
  );
};

export default App;

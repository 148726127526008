import React from "react";
import {
  BarChartOutlined,
  ExperimentOutlined,
  LikeOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import { Col, Row, Statistic, message } from "antd";
import styles from "./index.module.less";

const Count: React.FC = () => {
  return (
    <Row
      gutter={[16,16]}
      justify={"center"}
      align="middle"
      onClick={() => message.warning("正在开发...")}
    >
      <Col  md={6} xs={12} className={styles.textCenter}>
        <Statistic
          title="实验"
          value={4}
          prefix={<ExperimentOutlined />}
          className={styles.box}
        />
      </Col>
      <Col md={6} xs={12} className={styles.textCenter}>
        <Statistic
          title="实验人数"
          value={523}
          prefix={<UsergroupDeleteOutlined />}
          className={styles.box}
        />
      </Col>

      <Col md={6} xs={12} className={styles.textCenter}>
        <Statistic
          title="点赞数"
          value={320}
          prefix={<LikeOutlined />}
          className={styles.box}
        />
      </Col>
      <Col md={6} xs={12} className={styles.textCenter}>
        <Statistic
          title="课程数量"
          value={3}
          suffix={<BarChartOutlined />}
          className={styles.box}
        />
      </Col>
    </Row>
  );
};

export default Count;

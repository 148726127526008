import React from "react";
import styles from "./index.module.less";
import {
  LockOutlined,
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import ParticlesCom from "../../component/particles";
export default function Index() {
  const nav = useNavigate();
  const onFinish = () => {
    localStorage.setItem("token", JSON.stringify(new Date().getTime()));
    nav("/index");
  };

  const validateUsername = (_: any, value: any) => {
    if (value === "user") {
      return Promise.resolve();
    }
    return Promise.reject(new Error("访客账号为： user"));
  };

  const validatePassword = (_: any, value: any) => {
    if (value === "123456") {
      return Promise.resolve();
    }
    return Promise.reject(new Error("访客密码为： 123456"));
  };

  return (
    <div className={styles.container}>
      <ParticlesCom />
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <h3>湖南城市学院化学化工虚拟仿真平台</h3>
        <Form.Item
          name="username"
          rules={[
            { required: true, message: "账号不能为空!" },
            // { validator: validateUsername },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="账号"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "密码不能为空!" },
            // { validator: validatePassword },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            placeholder="密码"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>记住我</Checkbox>
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

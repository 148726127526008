import React, { useEffect, useState } from "react";
import Swiper from "../../component/swiper";
import Header from "../../component/header";
import styles from "./index.module.less";
import Tab from "../../component/tabs";
import Count from "../../component/count/idnex";
import ParticlesCom from "../../component/particles";
import { Outlet } from "react-router-dom";
import { Col, Row, Spin } from "antd";

export default function Index() {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
  }, []);

  return (
    <Spin spinning={!load}>
      <ParticlesCom></ParticlesCom>
      <div className={styles.layout}>
        <Row justify="center">
          <Col xs={22} sm={21} md={20} lg={19} xl={18} xxl={17}>
            <div className={styles.container}>
              <header>
                <Header></Header>
              </header>
              <section className={styles.swiper}>
                <Swiper></Swiper>
              </section>
              <section className={styles.count}>
                <Count></Count>
              </section>
              <main>
                <Tab></Tab>
                <Outlet />
                {/* <button onClick={() => setProjectIndex(PRPJECT.HEICHA)}>黑茶</button>
            <button onClick={() => setProjectIndex(PRPJECT.OTHER)}>other</button> */}
                {/* {projectIndex === PRPJECT.HEICHA && (
              <iframe src={path0} style={{ width: 900, height: 600 }}></iframe>
            )}
  
            {projectIndex === PRPJECT.OTHER && (
              <iframe src={path1} style={{ width: 900, height: 600 }}></iframe>
            )} */}
              </main>
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

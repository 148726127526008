import LabContent from "../../../../component/lab-content";

const content = [
  {
    id: 0,
    img: "https://tse4-mm.cn.bing.net/th/id/OIP-C.X_IwToPxqaYeAFhubX58ZQHaEf?rs=1&pid=ImgDetMain",
    title: "安化黑茶",
    des: "黑茶中茶氨酸的鉴定",
    url: "http://101.35.173.95:9632/",
  },
  {
    id: 1,
    img: "https://ts1.cn.mm.bing.net/th/id/R-C.f2533e78c32d61cfd065dc9168d0ab77?rik=qgaZD0BbPnK8YQ&riu=http%3a%2f%2fpic.baike.soso.com%2fp%2f20140320%2f20140320093900-1289002297.jpg&ehk=oy4p4Yut9yG9Jd4s42dIfB7fDGYWVz1%2bs4Lj89MjNI0%3d&risl=&pid=ImgRaw&r=0",
    title: "环氧乙烷",
    des: "环氧乙烷生产工艺实训",
    url: "http://101.35.173.95:9633/build/",
  },

  {
    id: 2,
    img: "https://ts1.cn.mm.bing.net/th/id/R-C.9cbab0b8571b0c1ba72951f09c33ec05?rik=QWRri9vcQMyxfA&riu=http%3a%2f%2fimg01.fuhai360.com%2ffzcagd%2f202302%2f202302141345495090.png&ehk=JyBEpljYBbCTdqxA82CQlwFjNKas%2fpLjjXMwQNygGPk%3d&risl=&pid=ImgRaw&r=0",
    title: "荧光定量",
    des: "实时荧光定量PCR法检测新型冠状病毒",
    url: "wwww.baidu.com",
  },
  {
    id: 3,
    img: "https://ts1.cn.mm.bing.net/th/id/R-C.3c40071e361919e5ccd2401773e3cd21?rik=1Tdc%2f7dijJJcow&riu=http%3a%2f%2fwww.hdchuquan.com%2fuploads%2fimage%2f20190829%2f1567046525228154.jpg&ehk=Dl%2bP3QE8OICwCGhH203ybDoybUjKOT65BuroPBDNEhQ%3d&risl=&pid=ImgRaw&r=0",
    title: "荧光衍生",
    des: "荧光衍生试剂的合成及其用于醛类的检测",
    url: "http://117.72.68.103:53534/",
  },

];

export default function LabCom() {
  return (
    <>
      <LabContent content={content} />
    </>
  );
}

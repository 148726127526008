import styles from "./index.module.less";
import React from "react";
// @ts-ignore
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

// The number of columns change by resizing the window
class ModalCom extends React.Component {
  render() {
    return (
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }} className={styles.container}>
        <Masonry>
          <div style={{ height: 300, backgroundColor: "red" }} className={styles.item}/>
          <div style={{ height: 200, backgroundColor: "red" }} className={styles.item} />
          <div style={{ height: 500, backgroundColor: "red" }} className={styles.item}/>
          <div style={{ height: 300, backgroundColor: "red" }} className={styles.item}/>
          <div style={{ height: 700, backgroundColor: "red" }} className={styles.item}/>
        </Masonry>
      </ResponsiveMasonry>
    );
  }
}

export default ModalCom;

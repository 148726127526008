import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Popover } from "antd";
import Typist from "../typist";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";

export default function User() {
  const nav = useNavigate();

  const { pathname } = useLocation();
  useEffect(() => {
    pathname !== "/login" && !localStorage.getItem("token") && nav("/login");
  }, [nav, pathname]);

  const leave = useCallback(() => {
    localStorage.removeItem("token");
    nav("/login");
  }, [nav]);

  useEffect(() => {
    const time = new Date().getTime();
    const oldTime = localStorage.getItem("token");

    const timeRes = Number(time) - Number(oldTime);

    if (timeRes > 600000) {
      leave();
    }

  }, [leave, pathname]);

  return (
    <Popover
      trigger={"click"}
      content={
        <Button onClick={leave} type="primary">
          退出登录
        </Button>
      }
    >
      <Typist />
      <Avatar shape="square" icon={<UserOutlined />} />
    </Popover>
  );
}

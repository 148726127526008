import React from "react";
import styles from "./index.module.less";
import Logo from "../logo";
import User from "../user";

export default function Header() {
  return (
    <div className={styles.container}>
      <Logo></Logo>
      <User></User>
    </div>
  );
}

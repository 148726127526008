import { TypeAnimation } from 'react-type-animation';

const Typist = () => {
  return (
    <TypeAnimation
      sequence={[
        '欢迎来到仪器分析虚拟仿真平台',
        3000, // wait 1s before replacing "Mice" with "Hamsters"
        '你好',
        1000,
      ]}
      wrapper="span"
      speed={50}
      style={{ fontSize: '1em', display: 'inline-block' }}
      repeat={Infinity}
    />
  );
};


export default Typist
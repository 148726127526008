import { Col, Row } from "antd";
import ContentBox, { ContentProps } from "../content-box";

interface LabContentProps {
  content: ContentProps[];
}

export default function LabContent({ content }: LabContentProps) {
  return (
    <div>
      <Row gutter={[40, 24]} >
        {content.map((e) => (
          <Col className="gutter-row" sm={12} xl={8} xs={24}	 key={e.id} >
            <ContentBox {...e}  />
          </Col>
        ))}
      </Row>
    </div>
  );
}

import { Navigate, createHashRouter } from "react-router-dom";
import Index from "../pages/index";
import Login from "../pages/login";
import LabCom from "../pages/index/child-page/lab";
import ModalCom from "../pages/index/child-page/modal";
import ClassCom from "../pages/index/child-page/class";
import Recommend from "../pages/index/child-page/recommend";

const router = createHashRouter([
  {
    path: "/",
    element: <Navigate to={"/index"}></Navigate>,
  },
  {
    path: "/index",
    element: <Index></Index>,
    children: [
      {
        path: "lab",
        element: <LabCom></LabCom>,
      },
      {
        path: "modal",
        element: <ModalCom></ModalCom>,
      },
      {
        path: "class",
        element: <ClassCom></ClassCom>,
      },
      {
        path: "recommend",
        element: <Recommend></Recommend>,
      },
      {
        path: "/index/",
        element: <Navigate to={"lab"}></Navigate>,
      },
    ],
  },
  {
    path: "/login",
    element: <Login></Login>,
  },
  {
    path: "/about",
    element: <div>About</div>,
  },
]);

export default router;
